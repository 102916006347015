import chroma from 'chroma-js'
import { get } from 'vuex-pathify'
import { colorParsley } from 'colorparsley' // eslint-disable-line
import { sRGBtoY, APCAcontrast } from 'apca-w3'
import palette from '@/config/palette'

// Color weights

const missingColorReplacements = {
  coolGray: 'blueGray',
  lightBlue: 'blue',
  rose: 'pink',
}

function getContrast(background, foreground) {
  const contrast = Math.abs(
    APCAcontrast(
      sRGBtoY(colorParsley(foreground)),
      sRGBtoY(colorParsley(background))
    )
  )
  return contrast
}

export default {
  computed: {
    pageColors: get('page/colors'),
    customColorsSettings: get('page/obj@data.settings.customColors'),

    hasCustomColors() {
      return !!this.customColorsSettings
    },

    mainColors() {
      return palette
    },

    mainColor() {
      if (this.hasCustomColors) {
        return this.customColorsSettings.main
      }

      let key = this.pageColors.main

      if (Object.keys(missingColorReplacements).includes(key)) {
        key = missingColorReplacements[key]
      }

      return this.mainColors[key]
    },

    accentColor() {
      if (this.hasCustomColors) {
        return this.customColorsSettings.accent
      }

      let key = this.pageColors.accent

      if (Object.keys(missingColorReplacements).includes(key)) {
        key = missingColorReplacements[key]
      }

      return this.mainColors[key]
    },

    bgColorKey() {
      const gradient = this.item.props.bgColor?.gradient

      if (gradient) {
        return gradient.color.split('-')[0]
      }

      return this.item.props.bgColor?.color
    },

    bgImageSrc() {
      return this.item.settings?.bgImage?.url
    },

    customColors() {
      const { main, accent } = this.customColorsSettings

      return {
        main,
        accent,
      }
    },

    paletteColors() {
      let accentKey = this.pageColors.accent

      if (Object.keys(missingColorReplacements).includes(accentKey)) {
        accentKey = missingColorReplacements[accentKey]
      }

      return {
        main: this.mains[700],
        accent: this.accents[600],
      }
    },

    colors() {
      const colors = this.hasCustomColors
        ? this.customColors
        : this.paletteColors

      return {
        ...colors,
        tint1: this.mains[50],
        tint2: this.accents[50],
        shade1: this.mains[900],
        shade2: this.accents[800],
        dark: chroma(this.accents[600]).set('hsl.l', 0.03).hex(),
        lightGray: this.mains[50],
        light: '#fff',
      }
    },

    shadowVar() {
      if (
        (!this.bgColorKey || this.bgColorKey === 'light') &&
        !this.item.name
      ) {
        return { '--shadowColor': '0, 0%, 25%' }
      }

      if (!this.bgColorKey) {
        return
      }

      const [h] = chroma(this.bgColor).hsl()
      const s = 100
      const l = this.bgColorKey === 'dark' ? 75 : this.bgColorIsDark ? 0 : 25

      const hslString = `${isNaN(h) ? '0' : h}, ${s}%, ${l}%`

      return { '--shadowColor': hslString }
    },

    cssColorVars() {
      const [bH, bS, bL] = chroma(this.bgColor ?? '#fff').hsl()
      const [cH, cS, cL] = chroma(this.contrastColor).hsl()

      return {
        '--bgColor': this.bgColor,
        '--bgColorH': bH || '0',
        '--bgColorS': `${bS * 100}%`,
        '--bgColorL': `${bL * 100}%`,
        '--headlineColor': this.headlineColor,
        '--textColor': this.textColor,
        '--contrastColor': this.contrastColor,
        '--contrastColorH': cH,
        '--contrastColorS': `${cS * 100}%`,
        '--contrastColorL': `${cL * 100}%`,
        '--lowContrastColor': this.lowContrastColor,
        '--contrastOverlayColor': this.contrastOverlayColor,
        '--textGradient': this.textGradient,
      }
    },

    hsValues() {
      const [tint1h, tint1s] = chroma(this.colors.tint1).hsl()
      const [tint2h, tint2s] = chroma(this.colors.tint2).hsl()

      return {
        tint1: {
          h: tint1h,
          s: `${tint1s}%`,
        },
        tint2: {
          h: tint2h,
          s: `${tint2s}%`,
        },
      }
    },

    // COLORS
    accents() {
      return this.generateColorScale(this.accentColor)
    },

    mains() {
      return this.generateColorScale(this.mainColor)
    },

    // UTILS
    bgColorIsDark() {
      return this.colorIsDark(this.bgColor)
    },

    contrastColorIsDark() {
      return this.colorIsDark(this.contrastColor)
    },

    // STYLES

    bgColorIsGradient() {
      return !!this.item.props.bgColor?.gradient
    },

    bgColor() {
      if (!this.bgColorKey && this.bgImageSrc) {
        return this.colors.dark
      }
      return this.colors?.[this.bgColorKey]
    },

    gradients() {
      return {
        dark: ['dark', 'dark'],
        shade1: ['shade1', 'main'],
        shade2: ['shade2', 'accent'],
        main: ['main', 'main'],
        accent: ['accent', 'accent'],
        tint1: ['tint1', 'tint1'],
        tint2: ['tint2', 'tint2'],
        lightGray: ['lightGray', 'light'],
        light: ['light', 'tint2'],
      }
    },

    textGradient() {
      const [startColor, endColor] =
        this.bgColorKey === 'dark'
          ? [this.accents[300], this.mains[300]]
          : this.bgColorIsDark
          ? [this.accents[100], this.mains[100]]
          : [this.accents[600], this.mains[600]]

      const middleColors = chroma
        .scale([startColor, endColor])
        .mode('hcl')
        .colors(1)

      const colors = [startColor, ...middleColors, endColor]
      return `linear-gradient(45deg, ${colors})`
    },

    bgGradient() {
      if (!this.bgColorIsGradient) {
        return
      }

      const {
        type,
        color,
        origin,
        transition,
        balance,
        mixColor,
        vividness,
        smoothness,
      } = this.item.props.bgColor.gradient

      const [color1, color2] = this.gradients[color]

      const isSharp = transition === 'sharp'

      let balancePos = balance

      if (typeof balance === 'string') {
        balancePos = {
          under: 30,
          even: 50,
          over: 70,
        }[balance]
      }

      let startColor = this.colors[color1]
      let endColor =
        mixColor === 'transp'
          ? 'rgba(0,0,0,0)'
          : this.colors[mixColor] ?? this.colors[color2] ?? color2

      if (!mixColor && color1 === color2) {
        const [l, c, h] = chroma(endColor).lch()
        endColor = chroma.lch(l - 5, c + 5, h).hex()
      }

      startColor = chroma(startColor).hex()
      endColor = chroma(endColor).hex()

      const mode = {
        1: 'lab',
        2: 'hcl',
        3: 'hsl',
      }[vividness]

      const middleColors = !isSharp
        ? chroma
            .scale([startColor, endColor])
            .mode(mode)
            .colors(smoothness ?? 3)
        : []

      if (isSharp) {
        startColor += ` ${balancePos}%`
        endColor += ` calc(${balancePos}% + 1px)`
      }

      const radialType = 'circle'

      const originDegrees =
        origin.length > 5
          ? {
              'top left': '135deg',
              'top center': '180deg',
              'top right': '225deg',
              'center right': '270deg',
              'center left': '90deg',
              'bottom left': '45deg',
              'bottom center': '360deg',
              'bottom right': '315deg',
            }[origin]
          : `${origin}deg`

      const colorSteps = [startColor, ...middleColors, endColor]

      const gradients = {
        linear: `linear-gradient(${originDegrees}, ${colorSteps})`,
        radial: `radial-gradient(${radialType} at ${origin}, ${colorSteps})`,
      }

      return gradients[type]
    },

    headlineColor() {
      if (this.bgColorIsGradient && !this.bgColorIsDark) {
        if (['tint1', 'light'].includes(this.bgColorKey)) {
          return this.mains[900]
        }
        return this.accents[900]
      }

      if (!this.bgColorKey && this.bgImageSrc) {
        return this.colors.light
      }

      if (
        !this.bgColorKey ||
        ['lightGray', 'light'].includes(this.bgColorKey)
      ) {
        return this.colors.dark
      }

      if (this.bgColorKey === 'dark') {
        if (this.bgImageSrc) {
          return this.colors.light
        }
        return chroma(this.accents[100]).set('lch.c', '-5').hex()
      }

      if (this.bgColorKey === 'tint1') {
        return chroma(this.mains[900]).set('lch.c', '-10').hex()
      }

      if (this.bgColorKey === 'tint2') {
        return chroma(this.accents[900]).set('lch.c', '-10').hex()
      }

      return this.getContrastColor(this.bgColor, this.mains, 85)
    },

    textColor() {
      if (
        this.contrastIsOk(
          this.bgColor || this.colors.light,
          this.headlineColor,
          75
        )
      ) {
        return chroma(this.headlineColor).alpha(0.75).hex()
      }

      return this.headlineColor
    },

    lowContrastColor() {
      return chroma(this.contrastColor).alpha(0.25).hex()
    },

    contrastColor() {
      const threshold = this.bgColorIsDark ? 55 : 65
      const color = chroma(
        this.getContrastColor(this.bgColor || '#fff', this.accents, threshold)
      )

      return color.hex()
    },

    contrastOverlayColor() {
      const color = this.getContrastColor(
        this.contrastColor,
        this.contrastColor,
        75
      )
      return color
    },
  },

  methods: {
    generateColorScale(color) {
      const scale = chroma
        .bezier(['black', color, 'white'])
        .scale()
        .mode('lch')
        .correctLightness()

      return Object.fromEntries(
        [96, 91, 85, 76, 67, 58, 49, 40, 31, 25].map((l, idx, arr) => {
          const keyBase = idx === 0 ? 5 : idx * 10

          return [
            keyBase * 10,
            chroma(scale(l / 100))
              .set('hcl.c', `+${2 + idx * 3.5}`)
              .hex(),
          ]
        })
      )
    },

    contrastIsOk(background, color, threshold = 50) {
      const contrast = getContrast(background, color) >= threshold
      return contrast
    },

    getContrastColor(background, colorOrScale, threshold = 50) {
      const scale =
        typeof colorOrScale === 'string'
          ? this.generateColorScale(colorOrScale)
          : colorOrScale

      let weights = [50, 100, 200, 300, 400, 500, 600, 700, 800, 900]

      if (this.bgColorIsDark) {
        weights = weights.reverse()
      }

      const weight = weights.find(weight => {
        return this.contrastIsOk(background, scale[weight], threshold)
      })

      if (weight) {
        return scale[weight]
      }

      const darkColorContrast = getContrast(background, scale[900])
      const lightColorContrast = getContrast(background, scale[50])

      if (darkColorContrast > lightColorContrast) {
        return scale[900]
      }
      return 'white'
    },

    colorIsDark(color) {
      if (!color) {
        return
      }
      return chroma(color).get('lab.l') < 70
    },
  },
}
