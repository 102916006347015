var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',_vm._g({staticClass:"flex relative flex-col section",class:Object.assign({}, {'drop-shadow': _vm.boxShadow && _vm.sectionDividerAngle,
    'box-shadow': _vm.boxShadow && !_vm.sectionDividerAngle},
    _vm.paddingClasses,
    _vm.sectionModifierClasses,
    _vm.sectionHeightClasses),style:({
    '--shadowColor': '0, 0%, 25%',
    zIndex: _vm.sectionZindex,
    paddingBottom: _vm.angleBottomPadding,
  }),attrs:{"id":_vm.item.slug || _vm.item.uid,"data-uid":_vm.item.uid,"data-idx":_vm.index}},_vm.eventListeners),[_c('div',{staticClass:"section__background",style:(Object.assign({}, {backgroundImage: _vm.bgGradient},
      _vm.sectionDividerVars)),attrs:{"data-bg-color-key":!_vm.bgFlags.gradient ? _vm.bgColorKey : undefined}},[(_vm.bgFlags.pattern)?_c('BgPattern',{staticClass:"absolute top-0 right-0 w-full h-full",attrs:{"pattern":_vm.item.props.bgPattern,"color":_vm.bgPatternColor}}):_vm._e(),_vm._v(" "),(_vm.bgFlags.img)?_c('PageSectionImgBg',{attrs:{"has-gradient":_vm.bgFlags.gradient,"bg-color-name":_vm.bgColorName,"bg-image-source":_vm.bgImageSrc,"bg-image-url":_vm.bgImageUrl,"section-settings":_vm.item.settings}}):_vm._e()],1),_vm._v(" "),(_vm.showDropZone)?_c('div',{staticClass:"absolute right-0 bottom-0 z-1000 w-full h-full text-white bg-black bg-opacity-90 flex-center"},[_vm._m(0)]):_vm._e(),_vm._v(" "),_c(_vm.isAdminMode ? 'SortableList' : 'div',_vm._b({tag:"Component",staticClass:"lg:grid grid-cols-12 gap-y-16 lg:px-8 mx-auto w-full max-w-screen-xl section-blocks",class:{
      'flex-grow': _vm.sectionHeight === '1:1',
      'content-start': _vm.item.props.valign === 'top',
      'content-center': _vm.item.props.valign === 'middle',
      'content-end': _vm.item.props.valign === 'bottom',
    },style:(_vm.sectionBlockWrapperStyles),model:{value:(_vm.blocks),callback:function ($$v) {_vm.blocks=$$v},expression:"blocks"}},'Component',_vm.sortableListBindings,false),_vm._l((_vm.item.items),function(block,idx){return _c('BlockWrapper',{key:block.uid,style:(_vm.shadowVar),attrs:{"index":idx,"item":block,"parent":_vm.item,"block-meta-data":_vm.sectionBlocksDictionary[block.uid]}})}),1)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-center"},[_c('p',{staticClass:"mb-4 text-sm"},[_vm._v("Click to place block here")])])}]

export { render, staticRenderFns }