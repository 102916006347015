export default {
  pink: '#CD3672',
  red: '#C74545',
  orange: '#BE5006',
  brown: '#916A4A',
  green: '#04825B',
  teal: '#0E8073',
  cyan: '#047D95',
  blue: '#0074CC',
  indigo: '#4669DE',
  violet: '#7660D2',
  blueGray: '#687383',
}
