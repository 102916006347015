<template>
  <div
    :id="item.slug || item.uid"
    :data-uid="item.uid"
    :data-idx="index"
    class="flex relative flex-col section"
    :style="{
      '--shadowColor': '0, 0%, 25%',
      zIndex: sectionZindex,
      paddingBottom: angleBottomPadding,
    }"
    :class="{
      'drop-shadow': boxShadow && sectionDividerAngle,
      'box-shadow': boxShadow && !sectionDividerAngle,
      ...paddingClasses,
      ...sectionModifierClasses,
      ...sectionHeightClasses,
    }"
    v-on="eventListeners"
  >
    <div
      class="section__background"
      :data-bg-color-key="!bgFlags.gradient ? bgColorKey : undefined"
      :style="{
        backgroundImage: bgGradient,
        ...sectionDividerVars,
      }"
    >
      <BgPattern
        v-if="bgFlags.pattern"
        :pattern="item.props.bgPattern"
        class="absolute top-0 right-0 w-full h-full"
        :color="bgPatternColor"
      />

      <PageSectionImgBg
        v-if="bgFlags.img"
        :has-gradient="bgFlags.gradient"
        :bg-color-name="bgColorName"
        :bg-image-source="bgImageSrc"
        :bg-image-url="bgImageUrl"
        :section-settings="item.settings"
      />
    </div>

    <div
      v-if="showDropZone"
      class="absolute right-0 bottom-0 z-1000 w-full h-full text-white bg-black bg-opacity-90 flex-center"
    >
      <div class="text-center">
        <p class="mb-4 text-sm">Click to place block here</p>
      </div>
    </div>

    <Component
      :is="isAdminMode ? 'SortableList' : 'div'"
      v-model="blocks"
      :style="sectionBlockWrapperStyles"
      class="lg:grid grid-cols-12 gap-y-16 lg:px-8 mx-auto w-full max-w-screen-xl section-blocks"
      :class="{
        'flex-grow': sectionHeight === '1:1',
        'content-start': item.props.valign === 'top',
        'content-center': item.props.valign === 'middle',
        'content-end': item.props.valign === 'bottom',
      }"
      v-bind="sortableListBindings"
    >
      <BlockWrapper
        v-for="(block, idx) in item.items"
        :key="block.uid"
        :index="idx"
        :item="block"
        :parent="item"
        :style="shadowVar"
        :block-meta-data="sectionBlocksDictionary[block.uid]"
      />
    </Component>
  </div>
</template>

<script>
import { commit, dispatch, get } from 'vuex-pathify'

import colors from '@/mixins/colors'

export default {
  name: 'PageSection',

  components: {
    BlockWrapper: () => import('@/components/BlockWrapper'),
    BgPattern: () => import('@/components/BgPattern'),
    PageSectionImgBg: () => import('@/components/PageSectionImgBg'),
    SortableList: () => import('@/components/SortableList'),
  },

  mixins: [colors],

  props: {
    item: {
      type: Object,
      default: () => ({}),
    },

    index: {
      type: Number,
      default: 0,
    },
  },

  computed: {
    pageItems: get('page/items'),
    activeSectionUid: get('activeSectionUid'),
    isAdminMode: get('isAdminMode'),
    changeParentForItem: get('changeParentForItem'),
    sectionsData: get('page/sectionsData'),

    sectionData() {
      return this.sectionsData?.[this.index] ?? {}
    },

    sectionMeta() {
      return this.sectionData?.meta ?? {}
    },

    nextSectionMeta() {
      return this.sectionsData?.[this.index + 1]?.meta ?? {}
    },

    blocks: {
      get() {
        return this.item.items
      },

      set(value) {
        dispatch('page/update', {
          mutation: 'SET_BLOCK_ITEMS',
          item: { uid: this.item.uid }, // parent
          value,
        })
      },
    },

    sectionBlocksDictionary() {
      return Object.fromEntries(
        this.sectionData.rows.flat().map(({ uid, ...rest }) => [uid, rest])
      )
    },

    // BOOLEANS

    sectionIsActive() {
      return this.activeSectionUid === this.item.uid
    },

    sectionHasBg() {
      return Object.values(this.bgFlags).some(item => item)
    },

    sectionIsPageHeader() {
      return (
        this.item.items.length === 1 && this.item.items[0].name === 'PageHeader'
      )
    },

    sectionIsInfoBar() {
      return (
        this.item.items.length === 1 && this.item.items[0].name === 'InfoBar'
      )
    },

    // PROPS

    boxShadow() {
      return !!this.item.props.boxShadow
    },

    bgImageUrl() {
      const { filename, url } = this.item.settings?.bgImage ?? {}
      return filename ? `${this.$config.imageProxyUrl}/${filename}` : url
    },

    bgImageSrc() {
      return this.item.settings?.bgImage?.source ?? null
    },

    bgColorName() {
      return (
        this.item.props.bgColor?.color ||
        this.item.props.bgColor?.gradient?.color
      )
    },

    // Used by colors mixin
    parentBgColor() {
      return this.item.props.bgColor
    },

    valign() {
      return this.item.props.valign
    },

    colWidths() {
      return this.item.props.colWidths || '1:1'
    },

    sectionHeight() {
      return this.item.props.height
    },

    // BINDINGS - STYLES AND CLASSES

    sortableListBindings() {
      if (!this.isAdminMode) {
        return
      }

      return {
        'use-drag-handle': true,
        axis: 'x',
        'lock-axis': 'x',
        appendTo: `[data-uid="${this.item.uid}"] .section-blocks`,
      }
    },

    sectionModifierClasses() {
      return {
        'section--info-bar': this.sectionIsInfoBar,
        'section--page-header': this.sectionIsPageHeader,
        'section--active': this.sectionIsActive,
      }
    },

    sectionHeightClasses() {
      return {
        'min-h-screen-100': this.sectionHeight === '1:1',
      }
    },

    eventListeners() {
      if (!this.isAdminMode) {
        return
      }

      const vm = this
      return {
        click() {
          if (!vm.changeParentForItem) {
            return
          }

          if (!vm.showDropZone) {
            vm.$toast.clear()
            vm.$toast.error(
              "You can't place the block here, choose another one (or ESC to cancel)"
            )
            return
          }

          commit('page/CHANGE_PARENT', {
            targetParent: vm.item,
            item: vm.changeParentForItem,
          })

          vm.exitChangeParent()
          vm.$toast.clear()
        },

        mouseenter: e => {
          e.stopPropagation()
          this.setActiveSectionUid()
        },
      }
    },

    showDropZone() {
      return (
        !this.sectionIsPageHeader &&
        !this.sectionIsInfoBar &&
        this.changeParentForItem &&
        this.isAdminMode &&
        this.item.items.length === 1 &&
        !this.item.items.some(item => item.uid === this.changeParentForItem.uid)
      )
    },

    bgPatternColor() {
      if (!this.bgColor) {
        return this.contrastColor
      }

      if (this.bgColorIsDark) {
        return 'white'
      }

      if (this.bgColorKey === 'lightGray') {
        return 'black'
      }

      if (this.bgColorKey === 'tint1') {
        return this.colors.main
      }

      return this.colors.accent
    },

    sectionDividerAngle() {
      if (!this.sectionMeta.hasBg) {
        return null
      }

      return this.item.props.sectionDivider?.angle
    },

    prevSectionAnglePadding() {
      const prevSection = this.pageItems[this.index - 1]

      if (!prevSection) {
        return {}
      }

      const { bgColor, sectionDivider } = prevSection.props

      const hasBg =
        typeof bgColor?.color === 'string' ||
        typeof bgColor?.gradient?.color === 'string'

      if (!hasBg) {
        return {}
      }

      return { paddingTop: `${Math.abs(sectionDivider?.angle) * 0.66}vw` }
    },

    sectionDividerVars() {
      const angle = this.sectionDividerAngle

      if (!angle || (!this.bgFlags.color && !this.bgFlags.gradient)) {
        return {}
      }

      const value = Math.abs(angle)
      return {
        '--sectionDividerHeight': `calc(100% + ${value}vw)`,
        '--sectionDividerClipPath': `polygon(0 0, 100% 0, 100% ${
          angle < 0 ? `calc(100% - ${value}vw)` : '100%'
        }, 0 ${angle > 0 ? `calc(100% - ${value}vw)` : '100%'})`,
      }
    },

    nextSectionIsIdentical() {
      const nextSection = this.pageItems[this.index + 1]
      if (!nextSection) {
        return false
      }

      const thisSectionBlocks = this.item.items.map(item => item.name)
      const nextSectionBlocks = nextSection.items.map(item => item.name)

      return thisSectionBlocks.every(item => nextSectionBlocks.includes(item))
    },

    angleBottomPadding() {
      if (
        !this.sectionDividerAngle ||
        !this.sectionHasBg ||
        this.sectionIsPageHeader
      ) {
        return
      }
      const value = 16 - Math.abs(this.sectionDividerAngle)
      return `${value / 4}rem`
    },

    sectionZindex() {
      return 99 - this.index
    },

    sectionBlockWrapperStyles() {
      if (!this.bgColor && !this.bgGradient) {
        return this.prevSectionAnglePadding
      }

      return {
        ...this.cssColorVars,
        ...this.prevSectionAnglePadding,
      }
    },

    bgFlags() {
      return this.sectionMeta.bgFlags
    },

    paddingClasses() {
      const {
        firstBlockIsExpanded,
        lastBlockIsExpanded,
        firstRowHasBg,
        firstRowIsExpanded,
        lastRowHasBg,
        lastRowIsExpanded,
        hasBg,
      } = this.sectionMeta

      const tightBottomMargin =
        // this.nextSectionIsIdentical &&
        hasBg === false && this.nextSectionMeta.hasBg === false

      return {
        'lg:pt-16': firstRowHasBg && !firstRowIsExpanded,
        'lg:pt-20': !firstRowHasBg && !firstRowIsExpanded,

        'pb-4': tightBottomMargin,

        'lg:pb-16': !tightBottomMargin && lastRowHasBg && !lastRowIsExpanded,

        'lg:pb-20':
          !tightBottomMargin &&
          !lastRowHasBg &&
          !lastRowIsExpanded &&
          !this.sectionDividerAngle,

        'mob:pt-4': !firstBlockIsExpanded && this.index !== 0,
        'mob:pb-4':
          !tightBottomMargin &&
          !lastBlockIsExpanded &&
          this.index !== this.pageItems.length - 1,
      }
    },

    addBlocksGap() {
      return this.index === 0
    },
  },

  methods: {
    setActiveSectionUid() {
      commit('SET_ACTIVE_SECTION_UID', this.item.uid)
    },

    unsetActiveSectionUid() {
      commit('SET_ACTIVE_SECTION_UID', null)
    },

    exitChangeParent() {
      commit('SET_CHANGE_PARENT_FOR_ITEM', null)
    },
  },
}
</script>

<style lang="postcss">
.section-wrapper {
  width: 100%;
}

.section {
  .section__background {
    height: var(--sectionDividerHeight, 100%);
    clip-path: var(--sectionDividerClipPath);
    -webkit-backface-visibility: hidden;
  }
}

.section__background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
}

.section--info-bar,
.section--page-header {
  padding-top: 0;
  padding-bottom: 0;
  margin: 0;
  .section-block {
    width: 100%;
  }
}
</style>
